<template>
  <div>
    <div class="component__container">
      <h4 class="block__title">컴포넌트</h4>
      <p class="block__subtitle">
        큐픽의 다양한 컴포넌트를 미리 볼 수 있습니다.
      </p>
      <div class="dropdown">
        <button class="dropdown__button" @click.stop="toggleCategory">
          {{ categoryDefault.desc }}
        </button>
        <ul
          class="dropdown__list"
          v-show="categoryVisible"
          v-click-outside:toggleCategory
        >
          <li v-for="(category, index) in category" :key="index">
            <button @click="setCategory(category.type, category.desc)">
              {{ category.desc }}
            </button>
          </li>
        </ul>
      </div>
      <div class="DAV-blockClass__list">
        <masonry
          :cols="{default: 4, 1023: 3, 767: 2}"
          :gutter="{default: 24, 767: 16}"
        >
          <div
            class="DAV-blockClass__item"
            v-for="(component, index) in componentListData"
            :key="index"
          >
            <div class="DAV-blockClass__thumb">
              <button @click="componentPreview(component.id)">
                <img
                  :src="component.attachFile?.url"
                  :alt="component.name"
                  @error="errorImage(component.firstCategory, $event)"
                  @load="loadImage"
                />
              </button>
              <div
                class="DAV-blockClass__over"
                v-show="getUserDevice === 'desktop'"
              >
                <button @click="componentPreview(component.id)">
                  미리보기
                </button>
              </div>
              <div class="DAV-blockClass__desc">
                <span
                  class="DAV-blockClass__desc--new"
                  v-show="filterDate(component.createdAt)"
                >
                  NEW
                </span>
                <span
                  class="DAV-blockClass__desc--design"
                  v-show="
                    component.firstCategory === 'design' &&
                    categoryDefault.type !== 'design'
                  "
                >
                  디자인
                </span>
                <span
                  class="DAV-blockClass__desc--product"
                  v-show="
                    component.firstCategory === 'product' &&
                    categoryDefault.type !== 'product'
                  "
                >
                  상품
                </span>
              </div>
            </div>
          </div>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleComponent = createNamespacedHelpers("ModuleComponent");

const PerPage = 20;

import Vue from "vue";
import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

export default {
  data() {
    return {
      page: 1,
      totalPage: 1,
      categoryVisible: false,
      categoryDefault: {type: "design,product", desc: "전체"},
      category: [
        {type: "design,product", desc: "전체"},
        {type: "design", desc: "디자인"},
        {type: "product", desc: "상품"}
      ],
      componentListData: [],
      componentListSize: 0
    };
  },
  watch: {
    // 컴포넌트 목록
    getComponentListData(data) {
      this.componentListSize = data.list.length;
      this.componentListData.push(...data.list);

      this.totalPage = data.header.totalPage;
    }
  },
  computed: {
    ...ModuleEditor.mapGetters(["getUserDevice"]),
    ...ModuleComponent.mapGetters(["getComponentListData"])
  },
  methods: {
    // image error
    errorImage(category, e) {
      e.target.src = require(`@/assets/images/common/img_${category}.svg`);
    },
    // image load
    loadImage() {
      this.componentListSize--;
    },
    // NEW ICON 노출 유무
    filterDate(createdAt) {
      let dtcount = createdAt.indexOf("T");
      let componentDate = createdAt.slice(0, dtcount);

      let date = new Date();
      date.setMonth(date.getMonth() - 3);

      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let currentDate = `${year}-${month}-${day}`;

      return componentDate > currentDate;
    },
    // 컴포넌트 미리보기 선택
    componentPreview(componentId, e) {
      if (e) e.preventDefault();
      this.toggleCategory(false);
      window.open(
        "/introduction/component-preview?id=" + componentId,
        "_blank"
      );
    },
    // 카테고리 show/hide
    toggleCategory(value) {
      this.categoryVisible = !value ? value : !this.categoryVisible;
    },
    // 카테고리 선택
    setCategory(type, desc) {
      this.toggleCategory(false);

      if (this.categoryDefault.type === type) return;

      this.categoryDefault.type = type;
      this.categoryDefault.desc = desc;

      this.page = 1;
      this.componentListData = [];
      this.componentList();
    },
    // scroll event
    onScroll() {
      let atBottom =
        document.body.scrollHeight === window.scrollY + window.innerHeight;
      if (atBottom && this.page < this.totalPage) {
        this.page++;
        this.componentList();
      }
    },
    // 컴포넌트 목록
    componentList() {
      let payload = {};
      payload.sortBy = "-id";
      payload.page = this.page;
      payload.perPage = PerPage;
      payload.display = 1;
      payload.status = "registered";
      payload.firstCategory = this.categoryDefault.type;

      this.actComponentList(payload).then(() => {});
    },

    ...ModuleComponent.mapActions(["actComponentList"])
  },
  mounted() {
    // scroll event 추가
    window.addEventListener("scroll", this.onScroll);

    // 컴포넌트 목록
    this.componentList();
  },
  beforeDestroy() {
    // scroll event 삭제
    window.removeEventListener("scroll", this.onScroll);
  }
};
</script>

<style lang="scss" scoped>
.component__container {
  max-width: 1248px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 16px;
}
.block__title {
  margin-top: 48px;
  color: #484948;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
}
.block__subtitle {
  margin-top: 16px;
  color: #484948;
  font-size: 14px;
  line-height: 14px;
}
.dropdown {
  z-index: 2;
  position: relative;
  margin-top: 46px;

  &__button {
    width: 100px;
    height: 36px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #b7b7b7;
    color: #8d8d8f;
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-image: url($path + "bul_arr_bottom_gray.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  &__list {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    width: 100px;
    padding: 16px 0;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d8dae5;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

    button {
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: #484948;
      font-size: 14px;
    }
  }
}
.DAV-blockClass {
  &__list {
    z-index: 1;
    position: relative;
    padding: 24px 0 48px;
  }
  &__item {
    margin-bottom: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

    > button {
      position: relative;
      width: 100%;
      height: 32px;
      margin-top: 8px;
      background-color: #f7f9fc;
      border: 1px solid #b7b7b7;
      box-sizing: border-box;
      color: #8d8d8d;
      font-size: 14px;
    }
  }
  &__thumb {
    position: relative;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }
  &__over {
    display: none;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);

    > button {
      width: 120px;
      height: 40px;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__desc {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;

    span {
      display: flex;
      align-items: center;
      height: 20px;
      padding: 0 4px;
      font-size: 12px;
      font-weight: 500;
    }
    &--design {
      background-color: #ff7575;
      color: #ffffff;
    }
    &--product {
      background-color: #ffe175;
      color: #734e2c;
    }
    &--solution {
      background-color: #1d57dc;
      color: #ffffff;
    }
    &--new {
      background-color: #000000;
      color: #ffffff;
    }
    &--dynamic {
      background-color: #985fe8;
      color: #ffffff;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .dropdown {
    &__list {
      button:hover {
        background-color: #54c7a2;
        color: #fff;
      }
    }
  }
  .DAV-blockClass {
    &__thumb {
      &:hover {
        .DAV-blockClass__over {
          display: flex;
        }
      }
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 767px) {
  .DAV-blockClass {
    &__item {
      margin-bottom: 16px;
    }
  }
}
</style>
