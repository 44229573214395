var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "component__container"
  }, [_c('h4', {
    staticClass: "block__title"
  }, [_vm._v("컴포넌트")]), _c('p', {
    staticClass: "block__subtitle"
  }, [_vm._v(" 큐픽의 다양한 컴포넌트를 미리 볼 수 있습니다. ")]), _c('div', {
    staticClass: "dropdown"
  }, [_c('button', {
    staticClass: "dropdown__button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleCategory.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.categoryDefault.desc) + " ")]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categoryVisible,
      expression: "categoryVisible"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleCategory",
      arg: "toggleCategory"
    }],
    staticClass: "dropdown__list"
  }, _vm._l(_vm.category, function (category, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.setCategory(category.type, category.desc);
        }
      }
    }, [_vm._v(" " + _vm._s(category.desc) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "DAV-blockClass__list"
  }, [_c('masonry', {
    attrs: {
      "cols": {
        default: 4,
        1023: 3,
        767: 2
      },
      "gutter": {
        default: 24,
        767: 16
      }
    }
  }, _vm._l(_vm.componentListData, function (component, index) {
    var _component$attachFile;

    return _c('div', {
      key: index,
      staticClass: "DAV-blockClass__item"
    }, [_c('div', {
      staticClass: "DAV-blockClass__thumb"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.componentPreview(component.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": (_component$attachFile = component.attachFile) === null || _component$attachFile === void 0 ? void 0 : _component$attachFile.url,
        "alt": component.name
      },
      on: {
        "error": function error($event) {
          return _vm.errorImage(component.firstCategory, $event);
        },
        "load": _vm.loadImage
      }
    })]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.getUserDevice === 'desktop',
        expression: "getUserDevice === 'desktop'"
      }],
      staticClass: "DAV-blockClass__over"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.componentPreview(component.id);
        }
      }
    }, [_vm._v(" 미리보기 ")])]), _c('div', {
      staticClass: "DAV-blockClass__desc"
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.filterDate(component.createdAt),
        expression: "filterDate(component.createdAt)"
      }],
      staticClass: "DAV-blockClass__desc--new"
    }, [_vm._v(" NEW ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.firstCategory === 'design' && _vm.categoryDefault.type !== 'design',
        expression: "\n                  component.firstCategory === 'design' &&\n                  categoryDefault.type !== 'design'\n                "
      }],
      staticClass: "DAV-blockClass__desc--design"
    }, [_vm._v(" 디자인 ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.firstCategory === 'product' && _vm.categoryDefault.type !== 'product',
        expression: "\n                  component.firstCategory === 'product' &&\n                  categoryDefault.type !== 'product'\n                "
      }],
      staticClass: "DAV-blockClass__desc--product"
    }, [_vm._v(" 상품 ")])])])]);
  }), 0)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }